import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import config from "../config";
import axios from "axios";

import UserItem from "./UserItem";
import thumbUser from "../assets/images/user.png";
import noPayable from "../assets/images/noPayable.svg";
import ClipLoader from "react-spinners/ClipLoader";

import { Offcanvas, Form } from "react-bootstrap";

function Payables(props) {

    var user1 = localStorage.getItem('user');
    var user = JSON.parse(user1);

    const [receivables, setReceivables] = useState([]);
    const [hasData, setData] = useState(false);

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#34495E");
    const [show, setShow] = useState(false);
    

    const handleClose = () => {
        setShow(false);
        setFullName("");
        setMobileNo("");
        setAdded(false);

    }
  const handleShow = () => setShow(true);

    //const [accountName, setAccountName] = useState("");

    const MemberData = {
        UserID: user.UserID,
        AccessKey: user.AccessKey,
    };

    useEffect(() => {
        
        

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetMemberBalance',
            headers: {
                'Content-Type': 'application/json'
            },
            data: MemberData
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.Payable == 0) {
                    setData(false);
                }
                else {
                    setData(true);
                    setReceivables(response.data.ListofPayable);
                }
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });

            

    }, []);

    
    
    const [FullName, setFullName] = useState("");
    const [MobileNo, setMobileNo] = useState("");
  
    const [IsAdded, setAdded] = useState(false);
    const [SuccessResponse, setSuccessResponse] = useState('');
    const [RequestType, setRequestType] = useState('');
    const [buttonText, setbuttonText] = useState('');

    const AddPayable = (item,event) => {
        
        setShow(true);
        setFullName(item.FullName);
        setMobileNo(item.MobileNo);
        setRequestType(event.target.id);
        setbuttonText(event.target.id);
    }

    const handleAddReceivable = (event) => {
        event.preventDefault();
        

        const ReceivableData = {
            UserID: user.UserID,
            AccessKey: user.AccessKey,
            MobileNo: MobileNo,
            Name: FullName,
            Amount: event.target.amount.value,
            TransactionType: RequestType,
            Description: event.target.description.value,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/AddTransaction',
            headers: {
                'Content-Type': 'application/json'
            },
            data: ReceivableData
        };

        axios(api_config)
        .then(function (response) {
            if(response.data.status_code == 1)
            {
                setAdded(true);
                setSuccessResponse(response.data);
                renderData();
                handleClose();

            }
            else
            {
                setAdded(false);
            }
            
        })
        .catch(function (error) {
            console.log(error);
        });

    }



   


    const renderPayables = () => {
        
        return (
          
            receivables.map((item) => (

                <UserItem key={item.ID} 
                link={"/ledger/" + item.ID + "/" + user.UserID} 
                photo={item.MemberPhoto} 
                name={item.FullName} 
                mobile={item.MobileNo} 
                amount={item.Balance}
                button1Text="PAID"
                button2Text="Get More"
                AddPayableOne={(event) => AddPayable(item,event)}
                />
              
            ))


          
        );
      };



    const renderNoRecord = () => {
        return (
            <>
            <div className="empty">
                <img src={noPayable} />
                <h3>No Record Found</h3>
                <div className="text-center mt-4">
                    <Link to="/add-payable" className="button button-danger">Add Payable</Link>
                </div>
            </div>
            </>
        );
    }

    const Loader = () => {
        return(
            <div className="text-center pt-4 pb-4">
                <ClipLoader />
            </div>
        );
    }



    const renderData = () => {
        if(hasData){
            return renderPayables();
        }
        else
        {
            return renderNoRecord();
        }
    }


    const renderButton = () => {
        if(buttonText == "Receivable") {
            return <button className="button button-success">PAID</button>
        }
        else {
            return <button className="button button-danger">GET MORE</button>
        }
    }

    return (
        <>
          
            
            <Offcanvas className="canvasTC" show={show} onHide={handleClose} placement="end">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">Add Payable</h5>
                    <button onClick={handleClose} type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">

                {
                                    IsAdded === true &&
                                    <div className="alert alert-success">
                                        {SuccessResponse.status_message}
                                    </div>
                                }


                <Form noValidate onSubmit={handleAddReceivable}>
                <div className="ps-4 pt-2 pb-1 mb-3 pe-3 bg-light rounded-2">
                        <h3 className="mb-0">{FullName}</h3>
                        <p className="mb-2 mt-1">{MobileNo}</p>
                    </div>
                    
                    <div className="form-group mb-4">
                        <label>Amount</label>
                        <input type="text" name="amount" className="form-control" placeholder="e.g. 100" />
                    </div>
                    <div className="form-group mb-4">
                        <label>Description</label>
                        <input type="text" name="description" className="form-control" placeholder="e.g. Lunch" />
                    </div>
                    <div className="text-center">
                    {renderButton()}
                    </div>
                </Form>
                    
                </div>
                
            </Offcanvas>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h3 className="text-center mb-3">My Payables</h3>
                        <div className="card p-0">
                            <div className="card-body p-0">
                                {loading ? Loader() : renderData()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Payables;