import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import ClipLoader from "react-spinners/ClipLoader";
import Codes from "./Codes";

function LoginMobile(props) {

    return (
        <>
            <h5 className="text-center mb-4">Welcome<br /> Please enter your mobile number..</h5>
            <Form noValidate validated={props.validated} onSubmit={props.handleSubmit}>
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="row input-codes g-0">
                            <div className="col-5">
                                <Codes handleCodeChange={props.handleCodeChange} />
                            </div>
                            <div className="col-7">
                                <div className="form-group">
                                    <input type="number" name="mobile" id="inputMobile" className="form-control" placeholder="323123456" required />
                                    <div className="invalid-feedback">Please provide a valid Mobile Number.</div>

                                    {
                                        props.HasError === true &&
                                        <div className="invalid-feedback d-block">{props.message}</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="text-center mt-3">
                            {
                                props.isLoading === true &&
                                <div className="text-center pt-4 pb-4">
                                    <ClipLoader />
                                </div>
                            }
                            {
                                props.isLoading === false &&
                                <button disabled={props.isLoading} className="button button-primary">Continue..</button>
                            }

                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
}

export default LoginMobile;