import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './assets/css/style.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Login from './components/Login';
import Home from './components/Home';
import AddReceivable from './components/AddReceivable';
import AddPayable from './components/AddPayable';
import Receivables from './components/Receivables';
import Payables from './components/Payables';
import Ledger from './components/Ledger';
import Main from './components/Main';
import Notifications from './components/Notifications';
import Photo from './components/profile/Photo';
import UpdateProfile from './components/profile/UpdateProfile';

import Policy from './components/Policy';
import Support from './components/Support';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/privacy-policy" element={<Policy />} />
    <Route path="/support" element={<Support />} />
    <Route path="/"  element={<Main />}>
      <Route path="/home" element={<Home />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/add-receivable" element={<AddReceivable />} />
      <Route path="/add-payable" element={<AddPayable />} />
      <Route path="/receivables" element={<Receivables />} />
      <Route path="/payables" element={<Payables />} />
      <Route path="/update-photo" element={<Photo />} />
      <Route path="/profile" element={<UpdateProfile />} />
      <Route path="/ledger/:accountid/:userid" element={<Ledger />} />
    </Route>
    
    </Routes>
  </BrowserRouter>
);

