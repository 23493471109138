import React, { useState, useEffect, useRef } from "react";
import NotificationItem from "./NotificationItem";
import config from "../config";
import axios from "axios";

import Moment from 'moment';
import thumbUser from "../assets/images/user.png";
import noPayable from "../assets/images/noPayable.svg";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';


function Notifications(props) {

    var user1 = localStorage.getItem('user');
    var user = JSON.parse(user1);

    const [receivables, setReceivables] = useState([]);
    const [hasData, setData] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingButtons, setLoadingButtons] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showA, setShowA] = useState(false);
    const toggleShowA = () => setShowA(!showA);

    const [message, setMessage] = useState('');

    const MemberData = {
        UserID: user.UserID,
        AccessKey: user.AccessKey,
    };

    useEffect(() => {



        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetUnAcknowledgeTransactions',
            headers: {
                'Content-Type': 'application/json'
            },
            data: MemberData
        };

        axios(api_config)
            .then(function (response) {
               
                if (response.data.status_code === 0) {
                    setData(false);
                }
                else {
                    setData(true);
                    setReceivables(response.data.ListofUnAcknowledTransactions);
                }
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });



    }, [receivables]);



    const [ledgerItem, setLedgerItem] = useState({});


    const MarkEntry = (item, event) => {

        //console.log(event.target.id);
        //console.log(item);
        setLedgerItem(item);
        setShow(true);
    }


    const renderPayables = () => {
        
        return (


           
            receivables.map((item) => (
                <NotificationItem key={item.ID}
                    photo={thumbUser}
                    name={item.FullName}
                    Date={item.TransactionDate}
                    mobile={item.MobileNo}
                    Debit={item.Debit}
                    Credit={item.Credit}
                    Description={item.Description}
                    MarkTransaction={(event) => MarkEntry(item, event)}

                />

            ))
            
        );
    };







    const renderNoRecord = () => {
        return (
            <>
                <div className="empty">
                    <img src={noPayable} alt="photo" />
                    <h3>You don't have any notification.</h3>
                </div>
            </>
        );
    }

    const Loader = () => {
        return (
            <div className="text-center pt-4 pb-4">
                <ClipLoader />
            </div>
        );
    }


    




    const renderData = () => {
        if (hasData) {
            return (
                <>
                    <table className="table table-notifications table-card">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Description</th>
                                <th>Payable</th>
                                <th>Receivable</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderPayables()}
                        </tbody>
                    </table>
                </>
            )
        }
        else {
            return renderNoRecord();
        }
    }

    const inputDescription = useRef();
    
    const handleTransaction = (event) => {

        //setLoadingButtons(true);

        console.log(ledgerItem);

        const TransactionData = {
            UserID: user.UserID,
            AccessKey: user.AccessKey,
            ID: ledgerItem.ID,
            Description: inputDescription.current.value,
            AcceptReject: event.target.id
        }

        

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/AcknowledgeTransactions',
            headers: {
                'Content-Type': 'application/json'
            },
            data: TransactionData
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code === 1) {
                    setMessage(response.data.status_message);
                    setShowA(true);
                    setShow(false);
                    setLedgerItem("");
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }



    return (
        <>
        <ToastContainer position="top-end" className="p-3">
            <Toast show={showA} onClose={toggleShowA}>
                <Toast.Header>
                    <strong className="me-auto">Success</strong>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
            </ToastContainer>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{ledgerItem.FullName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-lg">
                        <tbody>
                        {
                            ledgerItem.Debit !== 0 &&
                            <tr>
                                <td>Deny hain</td>
                                <td>
                                    {ledgerItem.Debit}
                                </td>
                            </tr>
                        }
                        {
                            ledgerItem.Credit !== 0 &&
                            <tr>
                                <td>Leny hain</td>
                                <td>
                                    {ledgerItem.Credit}
                                </td>
                            </tr>
                        }
                        <tr>
                            <td>Date</td>
                            <td>
                                {Moment(ledgerItem.TransactionDate).format("MMMM D YYYY")}
                            </td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>
                                <input type="text" ref={inputDescription} defaultValue={ledgerItem.Description} className="form-control" />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">

                    <button className="button button-success" id="accept" onClick={handleTransaction}>
                        Accept
                    </button>
                    <button className="button button-danger" id="reject" onClick={handleTransaction}>
                        REJECT
                    </button>

                </Modal.Footer>
            </Modal>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h3 className="text-center mb-3">Notifications</h3>
                        {loading ? Loader() : renderData()}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notifications;