import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import config from "../config";
import axios from "axios";
import Placeholder from 'react-bootstrap/Placeholder';
import thumbUser from "../assets/images/user.png";
import LedgerItem from "./LedgerItem";


function Ledger(props) {

    let navigate = useNavigate();
    const moment = require('moment-timezone');
    var user1 = localStorage.getItem('user');
    var user = JSON.parse(user1);

    const [ledger, setLedger] = useState([]);
    const [ledgerUserName, setLedgerUserName] = useState('');
    const [ledgerPhoto, setLedgerPhoto] = useState('');
    const [ledgerUserMobile, setLedgerUserMobile] = useState('');
    const [ledgerUserFinalBalance, setLedgerUserFinalBalance] = useState('');
    const [totalPayable, setTotalPayable] = useState(0);
    const [totalReceivable, setTotalReceivable] = useState(0);
    const [balanceType, setBalanceType] = useState();
    const [PhotoLoaded, setPhotoLoaded] = useState(false);

    const params = useParams();


    const MemberData = {
        UserID: user.UserID,
        AccessKey: user.AccessKey,
        AccountID: params.accountid,
    };

    useEffect(() => {

        if (user1 == null) {
            navigate('/');
        }




        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetLedgerDetails',
            headers: {
                'Content-Type': 'application/json'
            },
            data: MemberData
        };

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    var path = "https://api.hisaber.com/MemberImages/";

                    response.data.MemberPhoto === null ? setLedgerPhoto(thumbUser) : setLedgerPhoto(path + response.data.MemberPhoto);
                    setPhotoLoaded(true);

                    setLedgerUserName(response.data.FullName);
                    setLedgerUserMobile(response.data.MobileNo);



                    setLedgerUserFinalBalance(response.data.FinalBalance);
                    setBalanceType(response.data.BalanceType);
                    setLedger(response.data.ListofLedger);


                    let totalP = 0;
                    let totalR = 0;
                    ledger.map((i) => {
                        totalP += i.Debit;
                        totalR += i.Credit;
                    });
                    setTotalReceivable(totalR);
                    setTotalPayable(totalP);


                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);


    const renderPhotoPlaceHolder = () => {
        return (
            <Placeholder animation="glow">
                <Placeholder style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%"
                }} />
            </Placeholder>
        );
    }


    const renderLedger = () => {
        return (


            ledger.map(item => (


                <LedgerItem key={item.ID}
                    date={item.TransactionDate}
                    debit={item.Debit}
                    credit={item.Credit}
                    description={item.Description}
                    IsAcknowledged={item.IsAcknowledged}
                    AcknowledgeDate={item.AcknowledgeDate} />
            ))

        );


    }




    return (
        <>

            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h3 className="text-center mb-3">Ledger</h3>
                        <div className="card p-0">




                            <div className="ledger_head">
                                <div className="photo">
                                    {PhotoLoaded ? <img src={ledgerPhoto} alt="photo" /> : renderPhotoPlaceHolder()}
                                    
                                </div>
                                <div className="info">
                                    <h3>{ledgerUserName}</h3>
                                    <p>{ledgerUserMobile}</p>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <table className="table table_ledger">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th className="text-end">Given</th>
                                            <th className="text-end">Taken</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderLedger()}
                                    </tbody>
                                    <tfoot>
                                        <tr style={{ backgroundColor: "#efefef", fontWeight: "bold" }}>
                                            <td className="text-end">Total</td>
                                            <td className="text-end">{totalPayable}</td>
                                            <td className="text-end">{totalReceivable}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className="text-end"><strong>Balance</strong></td>
                                            <td className="text-end"><strong>{ledgerUserFinalBalance}</strong></td>
                                            <td className="text-end">{balanceType}</td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Ledger;