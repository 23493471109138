import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.svg";
import { Link, useNavigate } from 'react-router-dom';
import config from "../config";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ClipLoader from "react-spinners/ClipLoader";

function Header(props) {

    let navigate = useNavigate();
    var user1 = localStorage.getItem('user');
    var user2 = JSON.parse(user1);

    const [user, SetUser] = useState(user2);
    const [UserName, SetUserName] = useState("");
    const [IsLoggedIn, setLoggedIn] = useState(false);
    const [count, setCount] = useState(0);
    const [ProfilePhoto, setProfilePhoto] = useState('');

    const [PhotoStatus, setPhotoStatus] = useState(true);

    const getFirstChar = (str) => {
        const firstChars = str
            .split(" ")
            .map((word) => word[0])
            .join("");
        return firstChars;
    };



    



    useEffect(() => {
        if (user1 != null) {
            const MemberData = {
                UserID: user.UserID,
                AccessKey: user.AccessKey,
            };

            if(user.MemberPhoto !== null) {
                var path = "https://api.hisaber.com/MemberImages/";
                setProfilePhoto(path + user.MemberPhoto);
                setPhotoStatus("photo");
                
            }
            else {
                let short = getFirstChar(user.FullName);
                SetUserName(short);
                setPhotoStatus(false);
                
            }

            
            setLoggedIn(true);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetUnAcknowledgeTransactions',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: MemberData
            };

            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 0) {
                        setCount(0);
                    }
                    else {
                        setCount(response.data.ListofUnAcknowledTransactions.length);
                    }


                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            SetUserName("");
        }

    }, []);


    const SignOut = () => {
        localStorage.removeItem("user");
        navigate("/login");
    }


    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);

    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);

            setLoading(true);

            console.log(cropData1);


            var cleanerBase64 = cropData1.substring(22);

            

            const MemberData = {
                UserID: user.UserID,
                AccessKey: user.AccessKey,
                MemberPhoto:  cleanerBase64,
            };

           

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/UpdatePhoto',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: MemberData
            };
    
            axios(api_config)
            .then(function (response) {
    
                if(response.data.status_code === 1) {
                    setLoading(false);
                    setShowModal(false);
               }
              
    
    
            })
            .catch(function (error) {
                console.log(error);
            });

        }
    };


    const Loader = () => {
        return (
            <div className="text-center pt-4 pb-4">
                <ClipLoader />
            </div>
        );
    }

    return (
        <>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-photo-wrap">
                    <div class="mb-3">
                            <input className="form-control" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                        </div>
                        <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={0.5}
                            aspectRatio={8 / 8}
                            preview=".img-preview"
                            src={image}
                            dragMode="move"
                            viewMode={2}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                    </div>
                </Modal.Body>
                <div className="modal-footer justify-content-center">
                    <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                    {loading ? Loader() : <button className="button button-primary" onClick={getCropData}>Save Photo</button>}

                    </div>
                
            </Modal>


            <header>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/home">
                            <img src={Logo} alt="" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span style={{ width: "1em" }} className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto nav-right">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/home">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/receivables">Receivables</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/payables">Payables</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/profile">Profile</Link>
                                </li>
                                <li className="nav-item">
                                    <a onClick={SignOut} style={{ cursor: 'pointer' }} className="nav-link">Sign Out</a>
                                </li>
                            </ul>

                            <div className="notifications">
                                <Link className="nav-link" to="/notifications">
                                    <span>{count}</span>
                                    <i className="ri-notification-3-line"></i>
                                </Link>

                            </div>
                            {
                                IsLoggedIn === true &&
                                <div className="user-logged-in">
                                    <div className="photo">
                                        {PhotoStatus ? <img onClick={handleShowModal} src={ProfilePhoto} /> : <a href="#" onClick={handleShowModal}>{UserName}</a>}
                                    </div>
                                    <div className="info">
                                        <h4>{user.FullName}</h4>
                                        <p>{user.MobileNo}</p>
                                    </div>
                                </div>
                            }


                        </div>
                    </div>
                </nav>


            </header>
        </>
    );
}

export default Header;