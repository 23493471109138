import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import thumbUser from "../assets/images/user.png";


function UserItem(props) {


    const [MemberPhoto, setMemberPhoto] = useState('');
    const renderPhoto = () => {
        if(props.photo === null) {
            setMemberPhoto(thumbUser);
        }
        else {
            var path = "https://api.hisaber.com/MemberImages/";
            setMemberPhoto(path + props.photo);
        }
    }

    useEffect(() => {
        renderPhoto();
    }, []);

    return (
        <>
            <div className="user_item">
                
                <div className="user">
                    <div className="photo">
                    <Link to={props.link}><img src={MemberPhoto} alt="" /></Link>
                    </div>
                    <div className="info">
                        <h3>{props.name}</h3>
                        <p>{props.mobile}</p>
                    </div>
                </div>
                <div className="amount text-center">
                    Rs. {props.amount}
                </div>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7L1 13" stroke="black" strokeWidth="1.16" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <button onClick={props.AddPayableOne} id="Receivable" className="button button-primary">
                {props.button1Text}
                </button>
                <button onClick={props.AddPayableOne} id="Payable" className="button button-outline-primary ms-2">
                    {props.button2Text} 
                </button>
            </div>
        </>
    );
}

export default UserItem;