import { useState, useEffect, useRef } from "react";
//import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import "cropperjs/dist/cropper.css";
import config from "../../config";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { UpdateUser } from "../UpdateUser";



function UpdateProfile(props) {

    //let navigate = useNavigate();
    var user1 = localStorage.getItem('user');
    var user2 = JSON.parse(user1);

    

    const [user, SetUser] = useState(user2);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [IsAdded, setAdded] = useState(false);
    const [SuccessResponse, setSuccessResponse] = useState('');
    const [HasError, setError] = useState(false);
    const [message, setMessage] = useState("");



    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {

            const ProfileData = {
                UserID: user.UserID,
                AccessKey: user.AccessKey,
                FullName: event.target.FullName.value,
                EmailAddress: event.target.EmailAddress.value,
                Gender: event.target.Gender.value,
            };




            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/UpdateProfile',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: ProfileData
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 1) {
                        setAdded(true);
                        UpdateUser(ProfileData.EmailAddress, ProfileData.FullName, ProfileData.Gender, user.MemberPhoto);
                        setSuccessResponse(response.data);
                    }
                    else {
                        setAdded(false);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });

        }

        setValidated(true);

    }


    const Loader = () => {
        return (
            <div className="text-center pt-4 pb-4">
                <ClipLoader />
            </div>
        );
    }



    return (
        <>
        
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h3 className="text-center mb-3">Update Profile</h3>
                        <div className="card p-md-5">
                            <div className="card-body">
                                {
                                    IsAdded === true &&
                                    <div className="alert alert-success">
                                        {SuccessResponse.status_message}
                                    </div>
                                }
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <div className="form-group mb-4">
                                        <label>Full Name</label>
                                        <input type="text" defaultValue={user.FullName} name="FullName" className="form-control" required />
                                    </div>

                                    <div className="form-group mb-4">
                                        <label>Email Address</label>
                                        <input type="email" defaultValue={user.EmailAddress} name="EmailAddress" className="form-control" required />
                                        <div className="invalid-feedback">Please enter a valid email.</div>
                                    </div>
                                    <div className="form-group row mb-4">

                                        <div className="col-2">
                                            <label>Gender</label>
                                        </div>
                                        <div className="col-10">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="Gender" id="inlineRadio1" value="1" required />
                                                <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="Gender" id="inlineRadio2" value="0" required />
                                                <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button className="button button-primary">Update Profile</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateProfile;