import React, { useState, useEffect } from "react";
import thumbUser from "../../assets/images/user.png";


function User(props) {


    const [MemberPhoto, setMemberPhoto] = useState('');
    const renderPhoto = () => {
        if(props.photo === null) {
            setMemberPhoto(thumbUser);
        }
        else {
            var path = "https://api.hisaber.com/MemberImages/";
            setMemberPhoto(path + props.photo);
        }
    }

    useEffect(() => {
        renderPhoto();
    }, []);

    return (
        <>
            <div className="user_suggestion" onClick={props.SelectSuggestion}>
                
                <div className="user">
                    <div className="photo">
                    <img src={MemberPhoto} alt="" />
                    </div>
                    <div className="info">
                        <h3>{props.name}</h3>
                        <p>{props.mobile}</p>
                    </div>
                </div>
                
                
            </div>
        </>
    );
}

export default User;