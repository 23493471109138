import { useState, useEffect, useRef } from "react";
//import { Link, useNavigate } from 'react-router-dom';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import config from "../../config";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

function Photo(props) {

    //let navigate = useNavigate();
    var user1 = localStorage.getItem('user');
    var user2 = JSON.parse(user1);

    const [user, SetUser] = useState(user2);
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);

    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {

            setCropData(cropper.getCroppedCanvas().toDataURL());
            setImageCropped(true);

        }
    };




    const UpdatePhoto = () => {

        setLoading(true);
        
        console.log(cropData);

        var cleanerBase64 = cropData.substring(22);
        
        

        const MemberData = {
            UserID: user.UserID,
            AccessKey: user.AccessKey,
            MemberPhoto:  cleanerBase64,
        };


      

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/UpdatePhoto',
            headers: {
                'Content-Type': 'application/json'
            },
            data: MemberData
        };

        axios(api_config)
        .then(function (response) {

           if(response.data.status_code === 1) {
                setLoading(false);
                
           }
           


        })
        .catch(function (error) {
            console.log(error);
        });




    }


    const Loader = () => {
        return (
            <div className="text-center pt-4 pb-4">
                <ClipLoader />
            </div>
        );
    }



    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div class="mb-3">
                            <label for="formFile" class="form-label">Choose a photo</label>
                            <input className="form-control" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                        </div>


                        <br />
                        <br />
                        <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={0.5}
                            aspectRatio={8 / 8}
                            preview=".img-preview"
                            src={image}
                            dragMode="move"
                            viewMode={2}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />

                        {
                            imageSelected === true &&
                            <div className="text-center mt-4">
                                <button className="button button-outline-primary" onClick={getCropData}>
                                    Crop Image
                                </button>
                            </div>
                        }




                    </div>
                    {
                        imageCropped === true &&
                        <div className="col-md-3 offset-md-2">
                            <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                            <div className="text-center mt-4">
                                {loading ? Loader() : <button className="button button-primary" onClick={UpdatePhoto}>Save Photo</button>}
                            </div>
                        </div>
                    }

                </div>
            </div>
        </>
    );
}

export default Photo;