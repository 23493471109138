import React, {useEffect} from "react";
import { Offcanvas } from "react-bootstrap";
import Logo from "../assets/images/logo.svg";

function Policy(props) {

    useEffect(() => {
        document.title = "Privacy Policy - Hisaber";
    }, []);
    return (
        <>
            <div className="container">
                <div className="bg-white shadow-sm p-5">
                    <div className="text-center">
                        <img src={Logo} />
                    </div>
                    <h2>Privacy Policy</h2>
                    <br />
                    <h5>Introduction</h5>
          <p>
            Our privacy policy will help you understand what information we
            collect at Maima Soft, how Maima Soft uses it, and what choices you
            have. <br />
            Maima Soft built the Hisaber app as a free app. This SERVICE is
            provided by Maima Soft at no cost and is intended for use as is.
            <br />
            If you choose to use our Service, then you agree to the collection
            and use of information in relation with this policy. The Personal
            Information that we collect are used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.<br />
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible in our website, unless
            otherwise defined in this Privacy Policy.
          </p>
          <h5>Information Collection and Use</h5>
          <p>
            For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to users name, gender, email address, mobile number and profile picture. We also use User's Phone Contact List for giving User's Easy Access to select from their own Contacts. The information that we request will be retained by us and used as described in this privacy policy. <br />
The app does use third party services that may collect information used to identify you.
          </p>
          <h5>Cookies</h5>
          <p>
            Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your devices’s internal memory.  <br />

            This Services does not uses these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collection information and to improve their services. You have the option  to either accept or refuse these cookies, and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.  
          </p>
          <h5>Device Information</h5>
          <p>
            We collect information from your device in some cases. The information will be utilized for the provision of better service and to prevent fraudulent acts. Additionally, such information will not include that which will identify the individual user.  
          </p>
          <p>A third party tracks your app install and app launch events with your permission.</p>
          <h5>Service Providers</h5>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p>
            We want to inform users of this Service that these third parties
            have access to your Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </p>
          <h5>Security</h5>
          <p>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>
          <h5>Children’s Privacy</h5>
          <p>
            This Services do not address anyone under the age of 13. We do not
            knowingly collect personal identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do necessary actions.
          </p>
          <h5>Changes to This Privacy Policy</h5>
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. These changes are effective immediately, after they are posted
            on this page.
          </p>
          <h5>Contact Us</h5>
          <p>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
            <br />
            Contact Information: <br />
            Email: <a href="#">info@maimasoft.com </a>
            <h6>Phone: +923045652350</h6>
          </p>
                </div>
            </div>
        </>
    );
}

export default Policy;