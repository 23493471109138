import React from 'react';
import Logo from "../assets/images/logo.svg";

function Support(props) {
    return (
        <>
            <div className="container mt-4">
                <div className="bg-white shadow-sm p-5">
                    <div className="text-center mb-3">
                        <img src={Logo} />
                    </div>


                    <h3>Support</h3>
                    <p>We value our customers and are always here to help. Whether you have a question, concern, or feedback, we are committed to providing you with prompt and personalized assistance. Please don't hesitate to reach out to us. We look forward to hearing from you!</p>

                    <div className='row mt-4'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Full Name:</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Email:</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Subject:</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Contact Number:</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group mb-3'>
                                        <label>Description:</label>
                                        <textarea className="form-control" rows="6"></textarea>
                                    </div>
                                    <button className='button button-primary'>SUBMIT</button>
                                </div>
                               
                            </div>
                        </div>
                        <div className='col-md-5 offset-md-1'>
                            <div className='d-flex align-items-center mb-3'>
                                <div className='icon'>
                                    <i className="ri-phone-line fs-3 me-3"></i>
                                </div>
                                <div className='info'>
                                    <h4><a href="tel:+923216590065">+92 321 6590065</a></h4>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mb-3'>
                                <div className='icon'>
                                    <i className="ri-mail-line fs-3 me-3"></i>
                                </div>
                                <div className='info'>
                                    <h4><a href="mailto:info@maimasoft.com">info@maimasoft.com</a></h4>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mb-3'>
                                <div className='icon'>
                                    <i className="ri-map-pin-line fs-3 me-3"></i>
                                </div>
                                <div className='info'>
                                    <h4 className='fs-5'>Office No. 16-A Block A
Palm City 28 KM Ferozepur Road Lahore, Pakistan</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Support;