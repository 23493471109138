import React from "react";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";


function LoginPassword(props) {


    return (
        <>
            <h5 className="text-center mb-4">Welcome <strong>{props.user.FullName}</strong><br /> Please enter your password..</h5>
            <Form noValidate validated={props.validated1}  onSubmit={props.handleSignIn}>
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                            {
                                props.message &&
                                <div className="alert alert-danger">{props.message}</div>
                            }
                            
                        
                        <div className="form-group">
                            <input type="password" name="password" className="form-control" placeholder="Enter Password" required />
                            <div className="invalid-feedback">Please enter your password.</div>
                        </div>
                        <div className="text-center mt-3">

                        {
                                props.isLoadingPassword === true &&
                                <div className="text-center pt-4 pb-4">
                                    <ClipLoader />
                                </div>
                            }
                            {
                                props.isLoadingPassword === false &&
                                <button className="button button-primary">Sign In..</button>
                            }
                            
                            <br /><br /><Link to="/">Back to Login</Link>

                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
}

export default LoginPassword;