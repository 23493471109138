export const UpdateUser = (Email, FullName, Gender, MemberPhoto) => {

    var user1 = localStorage.getItem('user');
    var user2 = JSON.parse(user1);
    localStorage.removeItem("user");
   

     const userObject = {
        AccessKey: user2.AccessKey,
        Address: user2.Address,
        Country: user2.Country,
        Created_Date: user2.Created_Date,
        EmailAddress: Email,
        FullName: FullName,
        Gender: Gender,
        Is_Active: true,
        Is_Deleted: false,
        Is_Verified: true,
        LastPasswordChanged: user2.LastPasswordChanged,
        MemberPhoto: MemberPhoto,
        MobileNo: user2.MobileNo,
        Updated_Date: user2.Updated_Date,
        UserID: user2.UserID,
        VerificationCode: user2.VerificationCode,
        status_code: user2.status_code,
        status_message: user2.status_message,
    }



    
    localStorage.setItem('user', JSON.stringify(userObject));


   
  
};
  