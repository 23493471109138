import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.svg";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import config from "../config";
import LoginMobile from "./LoginMobile";
import LoginPassword from "./LoginPassword";
import SignUp from "./SignUp";


function Login(props) {

    const [IsVerified, setVerified] = useState(false);
    const [userData, setUserdata] = useState();
    const [IsSignUp, setSignUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoadingPassword, setLoadingPassword] = useState(false);
    
    const [color, setColor] = useState("#34495E");
    const [MobileNo, setMobileNo] = useState("");
    const [countryCode, setCountryCode] = useState(92);
    const [signUpStatus, setSignUpStatus] = useState(false);
    let navigate = useNavigate();


    const [userStatus, setUserStatus] = useState("login");


    var user1 = localStorage.getItem('user');

    useEffect(() => {
        if (user1 != null) {
            navigate('/home');
        }
    }, []);



    const handleCodeChange = (e) => {
        setCountryCode(e.target.value);
    }

    const [validated, setValidated] = useState(false);
    const [HasError, setError] = useState(false);
    const [message, setMessage] = useState("");

    const handleSubmit = (event) => {

        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {

            setValidated(false);
            event.preventDefault();
            event.stopPropagation();
            //setUserStatus("login");

        }
        else {
            //setUserStatus("signup");
            setLoading(true);
            const getMemberData = {
                MobileNo: countryCode + event.target.mobile.value,
            };

            let number = event.target.mobile.value;

            if(number.startsWith(0) || number.startsWith(92))
            {
                
                setError(false);
                setValidated(false);
                setMessage("Mobile number should not start with 0 or Country Code");
                setError(true);
                setLoading(false);
            }
            else
            {
                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Members/GetMemberByMobileNo',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: getMemberData
                };
    
                axios(api_config)
                    .then(function (response) {
    
                        if (response.data.status_code == 1) {
    
                            //setVerified(true);
                            setUserStatus("verified");
                            setUserdata(response.data);
                        }
                        else if (response.data.status_code == 0) {
                            if (form.checkValidity() === false) {
                                setUserStatus("login");
                            }
                            else {
                                setMobileNo(getMemberData.MobileNo);
                                setUserStatus("signup");
                            }
    
                        }
                        else {
                            setUserStatus("login");
                        }
    
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }            
        }

        setValidated(true);
    }



    const [validated1, setValidated1] = useState(false);

    const handleSignIn = (event) => {


        const form = event.currentTarget;
        event.preventDefault();
        
        if (form.checkValidity() === false) {
            
            setValidated1(false);
            event.preventDefault();
            event.stopPropagation();
        }

        else {

            setLoadingPassword(true);
            const signInData = {
                MobileNo: userData.MobileNo,
                Password: event.target.password.value,
            };

            

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/SignIn',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: signInData
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 1) {
                        localStorage.setItem('user', JSON.stringify(response.data));
                        navigate('/home');
                    }
                    else {
                        setPasswordError(true);
                        setLoadingPassword(false);
                        setErrorMessage("Invalid Password, Please try again!");
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }


        setValidated1(true);


    }



    const [SignUpValidated, setSignUpValidated] = useState(false);
    const [mobileExist, setmobileExist] = useState(false);
    const [signUpMessage, setSignUpMessage] = useState("");
    

    const handleSignUp = (event) => {
        
        
        const form = event.currentTarget;
        event.preventDefault();
        
        if (form.checkValidity() === false) {
            
            setSignUpValidated(false);
            event.preventDefault();
            event.stopPropagation();
            
        }

        else
        {
            const signUpData = {
                MobileNo: event.target.mobile.value,
                FullName: event.target.name.value,
                Password: event.target.password.value,
            };


            const signInData = {
                MobileNo: event.target.mobile.value,
                Password: event.target.password.value,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/SignUp',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: signUpData
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 0) {
                        setmobileExist(true);
                        setSignUpMessage(response.data.status_message);
                    }
                    else if (response.data.status_code == 1) {
                        //setSignUpStatus(true);
                        //setSignUpMessage(response.data.status_message);

                       


                        var api_config1 = {
                            method: 'post',
                            url: config.base_url + 'Members/SignIn',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: signInData
                        };
            
                        axios(api_config1)
                            .then(function (response) {
                                if (response.data.status_code == 1) {
                                    localStorage.setItem('user', JSON.stringify(response.data));
                                    navigate('/home');
                                }
                                else {
                                    setPasswordError(true);
                                    setLoadingPassword(false);
                                    setErrorMessage("Invalid Password, Please try again!");
                                }
            
                            })
                            .catch(function (error) {
                                console.log(error);
                            });

                    }

                })
                .catch(function (error) {
                    console.log(error);
                });


            
        }


        setSignUpValidated(true);

        

        
    }

    const renderData = () => {
        
        if (userStatus == "verified") {
            return <LoginPassword 
            validated1={validated1} 
            user={userData} 
            message={errorMessage} 
            isLoadingPassword={isLoadingPassword} 
            handleSignIn={handleSignIn} />
        }
        else if (userStatus == "signup") {
            return <SignUp 
            SignUpValidated={SignUpValidated} 
            handleSignUp={handleSignUp} 
            mobileAlreadyExist={mobileExist}
            signUpMessage={signUpMessage}
            signUpStatus={signUpStatus}
            mobile={MobileNo} />
        }
        else if (userStatus == "login") {
            return <LoginMobile 
            handleCodeChange={handleCodeChange} 
            validated={validated} 
            handleSubmit={handleSubmit} isLoading={loading}
            message={message}
            HasError={HasError}
             />
        }
    }



    return (

        <>
            <div className="text-center pt-4 mb-5">
                <img src={Logo} alt="" />
            </div>
            <div className="container">
                {renderData()}
            </div>
        </>
    );
}

export default Login;