import React, { useState, useRef } from "react";
import { Form } from 'react-bootstrap';
import config from "../config";
import axios from "axios";
import Codes from "./Codes";
import User from "./autocomplete/User";
import ClipLoader from "react-spinners/ClipLoader";


function AddPayable(props) {

    const [IsAdded, setAdded] = useState(false);
    const [SuccessResponse, setSuccessResponse] = useState('');
    const [validated, setValidated] = useState(false);
    const [countryCode, setCountryCode] = useState(92);
    const [HasError, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [FullName, setFullName] = useState("");

    var user1 = localStorage.getItem('user');
    var user = JSON.parse(user1);



    const handleCodeChange = (e) => {
        setCountryCode(e.target.value);
    }


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {

            let number = event.target.mobile.value;

            if (number.startsWith(0) || number.startsWith(92)) {
                setError(true);
                setValidated(false);
                event.preventDefault();
                event.stopPropagation();
                setMessage("Mobile number should not start with 0 or Country Code");
            }
            else {
                const ReceivableData = {
                    UserID: user.UserID,
                    AccessKey: user.AccessKey,
                    MobileNo: countryCode + event.target.mobile.value,
                    Name: event.target.name.value,
                    Amount: event.target.amount.value,
                    TransactionType: "Payable",
                    Description: event.target.description.value,
                };

                var api_config = {
                    method: 'post',
                    url: config.base_url + 'Members/AddTransaction',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: ReceivableData
                };

                axios(api_config)
                    .then(function (response) {
                        if (response.data.status_code == 1) {
                            setAdded(true);
                            setSuccessResponse(response.data);
                        }
                        else {
                            setAdded(false);
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }




        }

        setValidated(true);

    }

    



    const renderSuggestions = (listOfSuggestions) => {

        return (
            <div className="sugguestions-wrap">
                <div className="suggestion-box">
                    {
                        listOfSuggestions.map((item) => (

                        <User key={item.ID}
                            photo={item.MemberPhoto}
                            name={item.FullName}
                            mobile={item.MobileNo}
                            SelectSuggestion={(event) => handleSelectSuggestion(item,event)}
                        />
                    ))}
                </div>
            </div>
        );
    };

    const Loader = () => {
        return (
            <div style={{
                position: "absolute",
                right: "10px",
                top: "35px"

            }}>
                <ClipLoader />
            </div>
        );
    }



    const nameRef = useRef(null);
    const mobileRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [listofAccounts, setListofAccounts] = useState([]);
    const [hasSuggestions, setHasSuggestions] = useState(false);
    const handleInput = () => {

        setLoading(true);
        let name = nameRef.current.value;

       

        const nameData = {
            UserID: user.UserID,
            AccessKey: user.AccessKey,
            Name: name

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Account/SearchAccounts',
            headers: {
                'Content-Type': 'application/json'
            },
            data: nameData
        };

        axios(api_config)
            .then(function (response) {
                
                if (response.data.status_code == 1) {
                    if(response.data.listofAccounts.length > 0) {
                        setHasSuggestions(true);
                        
                        setListofAccounts(response.data.listofAccounts);
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                        setHasSuggestions(false);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });

           


    }


    const handleSelectSuggestion = (item,event) => {
        
        nameRef.current.value = item.FullName;
        mobileRef.current.value = item.MobileNo.slice(2);
        setHasSuggestions(false);
    }



    return (
        <>


            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h3 className="text-center mb-3">Add Payable</h3>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className="card p-5">
                                <div className="card-body">
                                    {
                                        IsAdded === true &&
                                        <div className="alert alert-success">
                                            {SuccessResponse.status_message}
                                        </div>
                                    }
                                    <div className="form-group mb-4">
                                        <label>Enter Mobile Number</label>
                                        <div className="row input-codes g-0">
                                            <div className="col-4">
                                                <Codes handleCodeChange={props.handleCodeChange} />
                                            </div>
                                            <div className="col-8">
                                                <div className="form-group">
                                                    <input type="number" ref={mobileRef} name="mobile" id="inputMobile" className="form-control" placeholder="323123456" required />
                                                    <div className="invalid-feedback">Please provide a valid Mobile Number.</div>
                                                    {
                                                        HasError === true &&
                                                        <div className="invalid-feedback d-block">{message}</div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                    {
                                    loading === true &&
                                    Loader()
                                    }
                                        <label>Enter Name</label>
                                        <input type="text" className="form-control" ref={nameRef} onChange={handleInput} name="name" placeholder="e.g. Ali" autoComplete="off" required />
                                        

                                                    
                                        {
                                            hasSuggestions === true &&
                                            renderSuggestions(listofAccounts)
                                        }
                                    </div>
                                    <div className="form-group mb-4">
                                        <label>Enter Amount</label>
                                        <input type="text" className="form-control" name="amount" placeholder="e.g. 200" required />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label>Enter Description</label>
                                        <input type="text" className="form-control" name="description" placeholder="e.g. Lunch" />
                                    </div>
                                    <div className="text-center">
                                        <button className="button button-danger">Add Payable</button>
                                    </div>

                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddPayable;