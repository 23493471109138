import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Welcome from "../assets/images/welcome.svg";


function SignUp(props) {


  
    const renderSuccess = () => {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 offset-md-4 text-center">
                            <h3>Welcome to Hisaber!</h3>
                            <p>Your account has been created.</p>
                            <img src={Welcome} className="img-fluid" />
                            <Link to="/home" className="button button-primary">Please Login Now.</Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderForm = () => {

        return (
            <>
                <h5 className="text-center mb-4">Mobile Number not exists.<br /> Please Sign Up</h5>
                <Form noValidate validated={props.SignUpValidated} onSubmit={props.handleSignUp}>
                    <div className="row">
                        <div className="col-md-4 offset-md-4">
                            {
                                props.mobileAlreadyExist === true &&
                                <div className="alert alert-danger">{props.signUpMessage}</div>
                            }

                            <div className="form-group mb-3">
                                <label>Mobile Number</label>
                                <input type="text" name="mobile" value={props.mobile} className="form-control" placeholder="e.g. 03006521154" required />
                                <div className="invalid-feedback">Please enter your Mobile No.</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Full Name</label>
                                <input type="text" name="name" className="form-control" placeholder="e.g. Ali Hassan" required />
                                <div className="invalid-feedback">Please enter Full Name.</div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Password</label>
                                <input type="password" name="password" className="form-control" placeholder="Choose a password" required />
                                <div className="invalid-feedback">Please create a password.</div>
                            </div>
                            <div className="text-center mt-3">
                                <button className="button mb-3 button-primary">Sign Up..</button>
                                <br />
                                <Link to="/">Back to Login</Link>
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        )
    }


    const renderData = () => {
        
        if(props.signUpStatus === true)
        {
            renderSuccess();
        }
        else if(props.signUpStatus === false){
            renderForm();
        }
    }

    return (

        <>
            {props.signUpStatus ? renderSuccess() : renderForm()}
        </>
    );
}

export default SignUp;