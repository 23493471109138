import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function Main(props) {

    var user1 = localStorage.getItem('user');
    let navigate = useNavigate();
    const [IsLoggedIn, setLoggedIn] = useState(false);


    useEffect(() => {
        if(user1 == null)
        {
            navigate('/login');    
            //setLoggedIn(true);        
        }
        else
        {
            navigate('/home'); 
            setLoggedIn(true);        
        }
       
    }, []);


    return (
        <React.Fragment>
            <>
                <Header />
                <main id="main">
                    {
                        IsLoggedIn === true &&
                        <Outlet />
                    }
                    
                </main>
            </>
        </React.Fragment>
    );
}

export default Main;