import React, { useState, useEffect } from "react";
import Moment from 'moment';

function UserItem(props) {


    const [FormatedDate, SetDate] = useState("");

    useEffect(() => {

        
    SetDate(Moment(props.date).calendar());     
    //const date = new Date("2022-09-05T00:00:00");
    //SetDate(date.toString());
    


    }, []);

    return (
        <>
            <tr>
                <td>
                    <div className="userinfo">
                    <div className="photo">
                        <img src={props.photo} alt="" />
                    </div>
                    <div className="info">
                        <h3>{props.name}</h3>
                        <p>{props.mobile}</p>
                    </div>
                    </div>
                </td>
                <td>
                    {props.Description} <br />
                    {FormatedDate}
                </td>
                <td>
                    Rs. {props.Debit}
                </td>
                <td>
                    Rs. {props.Credit}
                </td>
                <td>
                    <div className="action-buttons">
                        <button onClick={props.MarkTransaction} id="accept" className="button button-success">
                            Accept
                        </button>
                        <button onClick={props.MarkTransaction} id="reject" className="button button-danger ms-2">
                            Reject
                        </button>
                    </div>
                </td>
            </tr>
        </>
    );
}

export default UserItem;