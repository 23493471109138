import React, { useState, useEffect } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Moment from 'moment';

function LedgerItem(props) {


    const [FormatedDate, SetDate] = useState("");
    const [Acknowledge, setAcknowledge] = useState("");

    useEffect(() => {
        
        SetDate(Moment(props.date).calendar());
        //console.log(Moment('2022-10-10T13:28:20.25').calendar());
       
    }, []);


    const renderStatus = () => {
        let AcknowledgeDate = props.AcknowledgeDate;
        let IsAcknowledged = props.IsAcknowledged;
        if (AcknowledgeDate === null && IsAcknowledged === false) {
            return (
                <>
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip>
                                Not seen yet
                            </Tooltip>
                        }
                    >
                        <i className="ri-question-line"></i>
                    </OverlayTrigger>
                </>

            );
        }
        else if (AcknowledgeDate != null && IsAcknowledged === false) {
            return (
                <>
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip>
                                Rejected {FormatedDate}
                            </Tooltip>
                        }
                    >
                        <i className="ri-close-circle-line text-danger"></i>
                    </OverlayTrigger>
                </>
                
            );
        }
        else if (AcknowledgeDate != null && IsAcknowledged === true) {
            return (
                <>
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip>
                                Accepted {FormatedDate}
                            </Tooltip>
                        }
                    >
                        <i className="ri-checkbox-circle-line text-success"></i>
                    </OverlayTrigger>
                </>
                
            );
        }
    }

    return (
        <>
            <tr>
                <td>
                    <h4 className="date">{FormatedDate}</h4>
                    <p>{props.description}</p>

                </td>
                <td className="text-end">{props.debit}</td>
                <td className="text-end">{props.credit}</td>
                <td className="text-center" style={{fontSize: "1.5rem"}}>
                    {renderStatus()}
                </td>
            </tr>
        </>
    );
}

export default LedgerItem;