import React, { useState, useEffect  } from "react";
import Logo from "../assets/images/logo.svg";
import { Link, useNavigate } from 'react-router-dom';
import config from "../config";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import Header from "./Header";

function Home(props) {

    let navigate = useNavigate();
    var user1 = localStorage.getItem('user');
    

    const [MemberBalance, setMemberBalance] = useState('');
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#34495E");

    useEffect(() => {
        if(user1 === null) {
            navigate("/login");
        }
        else {
            var user = JSON.parse(user1);
            const MemberData = {
                UserID: user.UserID,
                AccessKey: user.AccessKey,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetMemberBalance',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: MemberData
            };

            axios(api_config)
            .then(function (response) {
                setMemberBalance(response.data);   
                setLoading(false);      
                
                
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        

    }, []);


    if(MemberBalance.status_message == "Invalid Access Key.") {
        localStorage.removeItem("user");
        navigate("/login");
    }


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="row">
                        
                            <div className="col-md-6">
                                <div className="card_button">
                                    <Link to="/receivables"></Link>
                                    <div className="icon">
                                        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="28.5" cy="28.5" r="28.5" fill="#49B76F" fillOpacity="0.2" />
                                            <path d="M28 16C28 15.4477 28.4477 15 29 15C29.5523 15 30 15.4477 30 16L28 16ZM29.7071 42.7071C29.3166 43.0976 28.6834 43.0976 28.2929 42.7071L21.9289 36.3431C21.5384 35.9526 21.5384 35.3195 21.9289 34.9289C22.3195 34.5384 22.9526 34.5384 23.3431 34.9289L29 40.5858L34.6569 34.9289C35.0474 34.5384 35.6805 34.5384 36.0711 34.9289C36.4616 35.3195 36.4616 35.9526 36.0711 36.3431L29.7071 42.7071ZM30 16L30 42L28 42L28 16L30 16Z" fill="#49B76F" />
                                        </svg>

                                    </div>
                                    <div className="info">
                                        <p>Receivable</p>
                                        {
                                            loading === true &&
                                            <BeatLoader color={color} loading={loading} size={20} />
                                            
                                        }
                                        {
                                            loading === false &&
                                            <h3>Rs. {MemberBalance.Receiveable}</h3>
                                        }
                                        
                                        
                                    </div>
                                    <svg className="chevron" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L7 7L1 13" stroke="black" strokeWidth="1.16" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="text-center mt-3 mb-4">
                                    <Link to="/add-receivable" className="button button-success">Add Receivable</Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card_button">
                                    <Link to="/payables"></Link>
                                    <div className="icon">
                                        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="28.5" cy="28.5" r="28.5" fill="#F2555E" fillOpacity="0.2" />
                                            <path d="M30 42C30 42.5523 29.5523 43 29 43C28.4477 43 28 42.5523 28 42L30 42ZM28.2929 15.2929C28.6834 14.9024 29.3166 14.9024 29.7071 15.2929L36.0711 21.6569C36.4616 22.0474 36.4616 22.6805 36.0711 23.0711C35.6805 23.4616 35.0474 23.4616 34.6569 23.0711L29 17.4142L23.3431 23.0711C22.9526 23.4616 22.3195 23.4616 21.9289 23.0711C21.5384 22.6805 21.5384 22.0474 21.9289 21.6569L28.2929 15.2929ZM28 42L28 16L30 16L30 42L28 42Z" fill="#F2555E" />
                                        </svg>
                                    </div>
                                    <div className="info">
                                        <p>Payable</p>
                                        {
                                            loading === true &&
                                            <BeatLoader color={color} loading={loading} size={20} />
                                            
                                        }
                                        {
                                            loading === false &&
                                            <h3>Rs. {MemberBalance.Payable}</h3>
                                        }
                                    </div>
                                    <svg className="chevron" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L7 7L1 13" stroke="black" strokeWidth="1.16" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </div>
                                <div className="text-center mt-4">
                                    <Link to="/add-payable" className="button button-danger">Add Payable</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;